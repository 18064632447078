<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="outer-block">
            <div class="tab-titles">
                <div class="tab-options">
                    <div class="d-flex gap-closure-header-sub-con">
                        <div class="in-action">
                            <div class="primary-color tab-option-text"
                                :class="{ 'tab-active-option': activeTab === 'machine' }" @click="tabSwitch(1)">
                                Step Audit by Machines
                            </div>
                            <hr class="active-tab-scroll-hr" v-if="activeTab === 'machine'" />
                        </div>
                    </div>
                    <div class="d-flex gap-closure-header-sub-con">
                        <div class="in-action">
                            <div class="primary-color tab-option-text"
                                :class="{ 'tab-active-option': activeTab === 'pillar' }" @click="tabSwitch(2)">
                                Step Audit by Pillar
                            </div>
                            <hr class="active-tab-scroll-hr" v-if="activeTab === 'pillar'" />
                        </div>
                    </div>
                    <div class="d-flex gap-closure-header-sub-con">
                        <div class="in-action">
                            <div class="primary-color tab-option-text"
                                :class="{ 'tab-active-option': activeTab === 'monthly' }" @click="tabSwitch(3)">Monthly
                                Preview by WC
                            </div>
                            <hr class="active-tab-scroll-hr" v-if="activeTab === 'monthly'" />
                        </div>
                    </div>
                    <div class="d-flex gap-closure-header-sub-con">
                        <div class="in-action">
                            <div class="primary-color tab-option-text"
                                :class="{ 'tab-active-option': activeTab === 'yearly' }" @click="tabSwitch(4)">Yearly
                                Regional Preview
                            </div>
                            <hr class="active-tab-scroll-hr" v-if="activeTab === 'yearly'" />
                        </div>
                    </div>
                </div>
                <div class="button-dd">
                    <div title="Export to PDF" class="download-view action-btn" @click="downloadPdf">
                        <img alt="download" src="../../../assets/download_icon.svg" />
                    </div>
                </div>
            </div>
            <hr class="hr" />

            <!-- Assessment by Machine and pillar -->
            <div v-if="activeTab === 'machine' || activeTab === 'pillar'" class="main-con">
                <div class="mat-info-con" style="padding-top: 0">
                    <div class="plant-dd one-graph-per-row" style="display: flex">
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="committed_year" class="form-label select-lable lable-text"> Committed Year
                            </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                                v-model="committedYr" @change="apiCallByCurrentTab()">
                                <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                    {{ year }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="pillar" class="form-label select-lable lable-text"> Plant </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                                :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                                <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                    {{ plant.plantName + ", " + plant.regionCode }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                                v-model="workcenterId" @change="wcChanged()">
                                <option v-for="wc in workCenterList" v-bind:key="wc.workcenterId" :value="wc.workcenterId">
                                    {{ wc.workcenterName }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" v-if="activeTab === 'pillar'" style="margin-left: 10px">
                            <label for="pillar" class="form-label select-lable lable-text"> Pillar </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="pillarId"
                                v-model="pillarPrevSelected" @change="apiCallByCurrentTab()">
                                <option v-for="plr in pillarList" v-bind:key="plr.pillarId" :value="plr.pillarId">
                                    {{ plr.pillarCode }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" v-if="activeTab !== 'yearly'" style="margin-left: 10px">
                            <label for="classification" class="form-label select-lable lable-text"> Classification
                            </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="classification"
                                v-model="classificationSelected" @change="onClassificationChange()">
                                <option v-for="cls in classificationList" v-bind:key="cls.statusId" :value="cls.statusId">
                                    {{ cls.statusName }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" v-if="activeTab === 'machine'" style="margin-left: 10px">
                            <label for="Machine" class="form-label select-lable lable-text"> Machine </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="machineId"
                                v-model="machineId" @change="apiCallByCurrentTab()">
                                <option v-for="mc in machineList" v-bind:key="mc.machineId" :value="mc.machineId">
                                    {{ mc.machineName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="mat-info-con" v-if="activeTab === 'machine' || activeTab === 'pillar'">
                    <div v-for="(graphData, index) in graphDatas" :key="index" class="two-graph-per-row">
                        <div :id="'assessment-graph-div-' + index">
                            <div class="main-tab-act default-font-color" style="padding: 0 2rem">
                                <div v-if="activeTab === 'machine'">
                                    {{ machineHeader.machine }} {{ machineHeader.wc }} - {{ graphData.pillarCode }}
                                    ({{ machineHeader.plant }}) - {{ machineHeader.year }}
                                    <div style="font-size: 1.13rem">
                                        {{ classificationStatusM(graphData) }}
                                    </div>
                                </div>
                                <div v-if="activeTab === 'pillar'">
                                    {{ graphData.machineName }} {{ pillarHeader.wc }} - {{ pillarHeader.pillar }}
                                    ({{ pillarHeader.plant }}) - {{ pillarHeader.year }}
                                    <div style="font-size: 1.13rem">
                                        {{ classificationStatusP(graphData) }}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <canvas :id="'assessment-graph-' + index" style="background-color: white"></canvas>
                            </div>
                            <div class="graph-1-container">
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="background-color: #94c8db; margin: 0 0.5rem; width: 1rem; height: 1rem; border-radius: 1rem">
                                    </div>
                                    Target
                                </div>
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="margin: 0 0.5rem; background-color: #109b10; width: 1rem; height: 1rem; border-radius: 1rem">
                                    </div>
                                    Current >= Target
                                </div>
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="margin: 0 0.5rem; background-color: #f75357; width: 1rem; height: 1rem; border-radius: 1rem">
                                    </div>
                                    Current &lt; Target
                                </div>
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="margin: 0 0.5rem; background-color: #5a11aa; width: 2rem; height: 0.3rem; border-radius: 1rem">
                                    </div>
                                    Audit Score
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mat-info-con" style="justify-content: center" v-if="!graphDatas.length">
                    <div class="no-data">
                        <img alt="no data" src="../../../assets/No_Data.svg" />
                        <span class="ed-date">No data found</span>
                    </div>
                </div>
            </div>
            <!-- Assessment by Machine and pillar -->

            <!-- Assessment by Monthly Preview by WC -->
            <div v-else-if="activeTab === 'monthly'" class="main-con">
                <div class="mat-info-con" style="padding-top: 0">
                    <div class="plant-dd one-graph-per-row" style="display: flex">
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="pillar" class="form-label select-lable lable-text"> Plant </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                                :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                                <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                    {{ plant.plantName + ", " + plant.regionCode }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="month-year" class="form-label select-lable lable-text"> Month-YY </label>
                            <month-picker-input id="month-year" @change="showDate" class="select-text-pd"
                                :default-month="new Date().getMonth() + 1" :default-year="new Date().getFullYear()"
                                :no-default="true"></month-picker-input>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                                v-model="workcenterId" @change="apiCallByCurrentTab()">
                                <option v-for="wc in workCenterList" v-bind:key="wc.workcenterId" :value="wc.workcenterId">
                                    {{ wc.workcenterName }}
                                </option>
                            </select>
                        </div>

                        <div v-click-outside="onConfirmClick" class="plant-dd" style="margin-left: 10px">
                            <label for="region" class="form-label select-lable lable-text"> Pillar </label>
                            <div @click="showDropdown" class="dropdown">
                                <b-form-input class="form-control select-text select-text-pd cust-st-icon"
                                    :placeholder="`Select Pillar`" v-model="selectedPillarsText"> </b-form-input>
                            </div>
                            <div id="multi-select-options" class="multi-select" v-show="showOption">
                                <ul>
                                    <li v-for="(option, index) in multiSelectPillarList" :key="index">
                                        <b-form-checkbox type="checkbox" :checked="option.checkedBox"
                                            :name="'pllr' + option.pillarId" class="radio" :value="option"
                                            v-model="selectedPillars" @change="onPillarOptionCheck()"
                                            :disabled="option.approvalStatus">
                                            {{ option.regionName }}
                                        </b-form-checkbox>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="plant-dd" v-if="activeTab !== 'yearly'" style="margin-left: 10px">
                            <label for="classification" class="form-label select-lable lable-text"> Classification
                            </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="classification"
                                v-model="classificationSelected" @change="apiCallByCurrentTab()">
                                <option v-for="cls in classificationList" v-bind:key="cls.statusId" :value="cls.statusId">
                                    {{ cls.statusName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="mat-info-con" v-if="activeTab === 'monthly'">
                    <div v-for="(graphData, index) in graphDatas" :key="index" class="one-graph-per-row">
                        <div :id="'monthly-preview-graph-div-' + index">
                            <div class="main-tab-act default-font-color" style="padding: 0 2rem">
                                <div v-if="activeTab === 'monthly'">
                                    {{ pillarHeader.wc }} - {{ graphData.pillarCode }}
                                    ({{ pillarHeader.plant }}) - {{ monthYear.month }} {{ monthYear.year }}
                                    <div style="font-size: 1.13rem"> {{ graphData.statusId }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <canvas :id="'monthly-preview-graph-' + index"
                                    :height="graphData.lables.length > 4 ? graphData.lables.length * 20 : graphData.lables.length * 80"
                                    style="background-color: white"></canvas>
                            </div>
                            <div class="graph-1-container">
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="background-color: #94c8db; margin: 0 0.5rem; width: 1rem; height: 1rem; border-radius: 1rem">
                                    </div>
                                    Target
                                </div>
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="margin: 0 0.5rem; background-color: #109b10; width: 1rem; height: 1rem; border-radius: 1rem">
                                    </div>
                                    Current >= Target
                                </div>
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="margin: 0 0.5rem; background-color: #f75357; width: 1rem; height: 1rem; border-radius: 1rem">
                                    </div>
                                    Current &lt; Target
                                </div>
                                <div class="graph-div" style="align-items: center; width: 20%; justify-content: center">
                                    <div
                                        style="margin: 0 0.5rem; background-color: #5a11aa; width: 1rem; height: 1rem; border-radius: 1rem">
                                    </div>
                                    Audit Score
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mat-info-con" style="justify-content: center" v-if="!graphDatas.length">
                    <div class="no-data">
                        <img alt="no data" src="../../../assets/No_Data.svg" />
                        <span class="ed-date">No data found</span>
                    </div>
                </div>
            </div>
            <!-- Assessment by Monthly Preview by WC -->

            <!-- Assessment by Yearly Preview by WC -->
            <div v-else-if="activeTab === 'yearly'" class="main-con">
                <div class="mat-info-con" style="padding-top: 0">
                    <div class="plant-dd one-graph-per-row" style="display: flex">
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="committed_year" class="form-label select-lable lable-text"> Committed Year
                            </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                                v-model="committedYr" @change="apiCallByCurrentTab()">
                                <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                    {{ year }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="region" class="form-label select-lable lable-text"> Region </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="region"
                                v-model="regionId" @change="apiCallByCurrentTab()">
                                <option v-for="region in regionList" v-bind:key="region.regionId" :value="region.regionId">
                                    {{ region.regionName }}
                                </option>
                            </select>
                        </div>
                        <div class="plant-dd" style="margin-left: 10px">
                            <label for="pillar" class="form-label select-lable lable-text"> Pillar </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="pillarId"
                                v-model="pillarPrevSelected" @change="onPillarChange()">
                                <option v-for="plr in pillarList" v-bind:key="plr.pillarId" :value="plr.pillarId">
                                    {{ plr.pillarCode }}
                                </option>
                            </select>
                        </div>

                        <div v-click-outside="onConfirmClick" class="plant-dd" style="margin-left: 10px">
                            <label for="step" class="form-label select-lable lable-text"> Steps </label>
                            <div @click="showDropdown" class="dropdown">
                                <b-form-input class="form-control select-text select-text-pd cust-st-icon"
                                    :placeholder="`Select Step`" v-model="selectedStepsText"> </b-form-input>
                            </div>
                            <div id="multi-select-options" class="multi-select" v-show="showOption">
                                <ul>
                                    <li v-for="(option, index) in multiSelectStepList" :key="index">
                                        <b-form-checkbox type="checkbox" :checked="option.checkedBox"
                                            :name="'pllr' + option" class="radio" :value="option" v-model="selectedSteps"
                                            @change="onStepOptionCheck()">
                                            {{ option }}
                                        </b-form-checkbox>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mat-info-con page-heading-tab one-graph-per-row" style="margin: 1rem"
                    v-if="activeTab === 'yearly'">
                    <div class="two-graph-per-row" style="text-align: center">Total Machines</div>
                    <div class="two-graph-per-row" style="text-align: center">Machines under replications</div>
                </div>

                <div class="mat-info-con one-graph-per-row" style="padding: 0" v-if="activeTab === 'yearly'">
                    <div v-for="(graphData, index) in graphDatas" :key="index" class="two-graph-per-row"
                        :id="'yearly-graph-div-' + index"
                        style="margin: 0 0 2rem 0; display: flex; flex-direction: column; align-items: flex-end">
                        <div class="one-graph-per-row" :id="'yearly-total-graph-div-' + index"
                            v-show="graphData.forGraph === 'total'">
                            <div class="main-tab-act default-font-color" style="margin: 0 2rem">
                                <div v-if="activeTab === 'yearly'">
                                    <!-- NA -RE 2 -->
                                    {{ committedYr }} - {{ selectedRegion }} - {{ selectedPillarName }}
                                    {{ graphData.step }}
                                </div>
                            </div>
                            <div>
                                <canvas :id="'yearly-total-graph-' + index" style="background-color: white"></canvas>
                            </div>
                            <div class="one-graph-per-row" style="padding: 2rem 1rem 0px 3rem">
                                <div style="border: 1px solid #ddd" class="scroll">
                                    <b-table-lite id="yearly-preview-table" :items="graphData.tableRows"
                                        :fields="graphData.labels">
                                        <template #cell(Plant)="row">
                                            <div class="graph-div" style="align-items: center">
                                                <div class="legends" :style="{ 'background-color': row.value.plantColor }">
                                                </div>
                                                {{ row.value.plantName }}
                                            </div>
                                        </template>
                                    </b-table-lite>
                                </div>
                            </div>
                        </div>

                        <div class="one-graph-per-row" :id="'yearly-replication-graph-div-' + index"
                            v-show="graphData.forGraph === 'replication'">
                            <div class="main-tab-act default-font-color" style="margin: 0 2rem">
                                <div v-if="activeTab === 'yearly'">
                                    <!-- NA -RE 2 -->
                                    {{ committedYr }} - {{ selectedRegion }} - {{ selectedPillarName }}
                                    {{ graphData.step }}
                                </div>
                            </div>
                            <div>
                                <canvas :id="'yearly-replication-graph-' + index" style="background-color: white"></canvas>
                            </div>
                            <div class="one-graph-per-row" style="padding: 2rem 1rem 0px 3rem">
                                <div style="border: 1px solid #ddd" class="scroll">
                                    <b-table-lite id="yearly-preview-table" :items="graphData.tableRows"
                                        :fields="graphData.labels">
                                        <template #cell(Plant)="row">
                                            <div class="graph-div" style="align-items: center">
                                                <div class="legends" :style="{ 'background-color': row.value.plantColor }">
                                                </div>
                                                {{ row.value.plantName }}
                                            </div>
                                        </template>
                                    </b-table-lite>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mat-info-con" style="justify-content: center" v-if="!graphDatas.length">
                    <div class="no-data">
                        <img alt="no data" src="../../../assets/No_Data.svg" />
                        <span class="ed-date">No data found</span>
                    </div>
                </div>
            </div>
            <!-- Assessment by Yearly Preview by WC -->
        </div>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import { mapGetters } from "vuex";
import Chart from "chart.js/auto";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";
import Helper from "@/common/helper/index";
import { MonthPickerInput } from "vue-month-picker";

export default {
    name: "AssessmentReport",
    data() {
        return {
            helper: Helper,
            activeTab: "machine",
            showLoader: true,
            classificationList: [],
            classificationSelected: "",
            plantList: [],
            workCenterList: [],
            workcenterId: 2,
            plantId: {},
            machineId: 1,
            machineList: [],
            pillarList: [],
            pillarPrevSelected: 5,
            committedYear: [],
            regionList: [],
            regionId: 5,
            printData: [],
            committedYr: null,
            graphDatas: [],
            monthYear: {},
            showOption: false,
            multiSelectPillarList: [],
            multiSelectStepList: [],
            selectedPillarsText: "",
            selectedPillars: [],
            selectedStepsText: "",
            selectedSteps: [],
            maxStepPillarWise: {
                CSD: 6,
                DEC: 7,
                RE: 6,
                WPO: 5
            },
            forAuditTargetCurrentTemplate: {
                type: "bar",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: "Audit Score",
                            type: "line",
                            yAxisID: "y1",
                            animation: true,
                            data: [],
                            datalabels: {
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 14
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            radius: 1,
                            backgroundColor: "#5A11AA",
                            borderColor: "#5A11AA"
                        },
                        {
                            label: "Target",
                            data: [],
                            type: "bar",
                            yAxisID: "y",
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            datalabels: {
                                align: "center",
                                anchor: "center",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        // textStrokeColor "#fff",
                                        // textStrokeWidth 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 1
                                        },
                                        color: "#94C8DB"
                                    }
                                }
                            },
                            backgroundColor: ["#94C8DB"],
                            borderWidth: 1,
                            barPercentage: 0.8
                        },
                        {
                            label: "Current",
                            data: [],
                            type: "bar",
                            yAxisID: "y",
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            datalabels: {
                                align: "center",
                                anchor: "center",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        // textStrokeColor "#fff",
                                        // textStrokeWidth 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 1
                                        },
                                        color: "#F75357"
                                    }
                                }
                            },
                            backgroundColor: ["#F75357"],
                            borderWidth: 1,
                            barPercentage: 0.8
                        }
                    ]
                },
                options: {
                    responsive: true,
                    lineTension: 0,
                    elements: {
                        bar: {
                            // hoverBackgroundColor:'yellow'
                        }
                    },
                    scales: {
                        y1: {
                            suggestedMax: 100,
                            display: true,
                            position: "right",
                            title: {
                                display: true,
                                text: "Percentage",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            },
                            // grid line settings
                            grid: {
                                drawOnChartArea: false // only want the grid lines for one axis to show up
                            }
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: "Steps",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            },
                            suggestedMax: 5,
                            ticks: { stepSize: 1 }
                        },
                        x: {
                            grid: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: ""
                        },
                        legend: {
                            display: false
                        },
                        datalabels: {
                            formatter: (val) => `${val}`
                        }
                    }
                }
            },
            forMonthlyPreviewTemplate: {
                type: "bar",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: "Audit Score",
                            type: "line",
                            xAxisID: "x1",
                            animation: true,
                            data: [],
                            datalabels: {
                                // align: "end",
                                // anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 12
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            radius: 14,
                            backgroundColor: "#5A11AA",
                            borderColor: "#5A11AA"
                        },
                        {
                            label: "Target",
                            data: [],
                            type: "bar",
                            xAxisID: "x",
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            datalabels: {
                                align: "end",
                                anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 14
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            backgroundColor: ["#94C8DB"],
                            borderWidth: 1,
                            barPercentage: 0.8
                        },
                        {
                            label: "Current",
                            data: [],
                            type: "bar",
                            xAxisID: "x",
                            borderRadius: Number.MAX_VALUE,
                            borderSkipped: false,
                            datalabels: {
                                align: "end",
                                anchor: "end",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 14
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            backgroundColor: ["#F75357"],
                            borderWidth: 1,
                            barPercentage: 0.8
                        }
                    ]
                },
                options: {
                    indexAxis: "y",
                    responsive: true,
                    lineTension: 0,
                    elements: {
                        bar: {
                            // hoverBackgroundColor:'yellow'
                        }
                    },
                    scales: {
                        x1: {
                            suggestedMax: 100,
                            display: true,
                            position: "top",
                            title: {
                                display: true,
                                text: "Percentage",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            },
                            // grid line settings
                            grid: {
                                drawOnChartArea: false // only want the grid lines for one axis to show up
                            }
                        },
                        x: {
                            display: true,
                            title: {
                                display: true,
                                text: "Steps",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            },
                            suggestedMax: 5,
                            ticks: { stepSize: 1 }
                        },
                        y: {
                            grid: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: ""
                        },
                        legend: {
                            display: false
                        },
                        datalabels: {
                            formatter: (val) => `${val}`
                        }
                    }
                }
            },
            forYearlyStackedTemplate: {
                type: "bar",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: "",
                            data: [],
                            type: "bar",
                            borderSkipped: false,
                            datalabels: {
                                align: "center",
                                anchor: "center",
                                color: "#bbb",
                                labels: {
                                    value: {
                                        textStrokeColor: "#fff",
                                        textStrokeWidth: 3,
                                        font: {
                                            weight: 800,
                                            family: "BarlowB",
                                            size: 12
                                        },
                                        color: "#000"
                                    }
                                }
                            },
                            backgroundColor: ["#94C8DB"],
                            borderColor: "#444",
                            borderWidth: 0.51,
                            barPercentage: 0.8
                        }
                    ]
                },
                options: {
                    responsive: true,
                    lineTension: 0,
                    elements: {
                        bar: {
                            // hoverBackgroundColor:'yellow'
                        }
                    },
                    scales: {
                        y: {
                            stacked: true,
                            display: true,
                            title: {
                                display: true,
                                text: "No. of Machines",
                                padding: { top: 30, left: 0, right: 0, bottom: 0 },
                                fontStyle: "bold",
                                fontSize: 16,
                                fontColor: "#333"
                            },
                            suggestedMax: 5,
                            ticks: { stepSize: 1 }
                        },
                        x: {
                            stacked: true,
                            grid: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            }
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: ""
                        },
                        legend: {
                            display: false
                        },
                        datalabels: {
                            formatter: (val) => `${val}`
                        }
                    }
                }
            }
        };
    },

    components: {
        Breadcrumb,
        Loader,
        MonthPickerInput
    },
    directives: {
        clickOutside: {
            bind: function (el, binding, vnode) {
                console.log("clickOutside bind");
                el.event = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.event);
            },
            unbind: function (el) {
                console.log("clickOutside unbind");
                document.body.removeEventListener("click", el.event);
            },
            beforeMount: (el, binding) => {
                console.log("clickOutside beforeMount");
                el.clickOutsideEvent = (event) => {
                    if (!(el == event.target || el.contains(event.target))) {
                        binding.value();
                    }
                };
                document.addEventListener("click", el.clickOutsideEvent);
            },
            unmounted: (el) => {
                console.log("clickOutside unmounted");
                document.removeEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 3,
            subMenu: 6
        });
        this.$store.dispatch("breadcrumb", {
            title: "Step Audit Report",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Reports / ", primary: true, redirect: "/" },
                { name: "Machine Step Audit Report", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getCommittedYear();
    },
    mixins: [SpiderChartReport],
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData"]),
        selectedMachineName() {
            let pos = this.machineList.findIndex((item) => {
                console.log(item);
                return item.machineId === this.machineId;
            });
            if (pos >= 0) return this.machineList[pos].machineName;
            else return "";
        },
        selectedWorkCenterName() {
            let pos = this.workCenterList.findIndex((item) => item.workcenterId === this.workcenterId);
            return this.workCenterList[pos].workcenterName;
        },
        selectedPillarName() {
            let pos = this.pillarList.findIndex((item) => item.pillarId === this.pillarPrevSelected);
            return this.pillarList[pos].pillarCode;
        },
        selectedRegion() {
            let pos = this.regionList.findIndex((item) => item.regionId === this.regionId);
            return this.regionList[pos].regionName;
        },
        machineHeader() {
            return { machine: this.selectedMachineName, wc: `${this.selectedWorkCenterName}`, plant: `${this.plantId.plantName}`, year: `${this.committedYr}` };
        },
        pillarHeader() {
            return { pillar: this.selectedPillarName, wc: `${this.selectedWorkCenterName}`, plant: `${this.plantId.plantName}`, year: `${this.committedYr}` };
        }
    },
    methods: {
        tabSwitch(pos) {
            switch (pos) {
                case 1:
                    this.activeTab = "machine";
                    this.showLoader = true;
                    break;
                case 2:
                    this.activeTab = "pillar";
                    this.showLoader = true;
                    break;

                case 3:
                    this.activeTab = "monthly";
                    this.showLoader = true;
                    break;

                case 4:
                    this.activeTab = "yearly";
                    this.showLoader = true;
                    break;

                default:
                    break;
            }
            this.graphDatas = [];
            this.getCommittedYear();
        },
        downloadPdf() {
            this.download = true;
            this.showLoader = true;
            this.pdfMakeCall(this.activeTab + "-assessment", this.printData);
            setTimeout(() => {
                this.showLoader = false;
            }, 10000);
        },
        showDate(date) {
            this.monthYear = date;
            this.apiCallByCurrentTab();
        },
        showDropdown() {
            this.showOption = !this.showOption;
        },
        onPillarOptionCheck() {
            this.selectedPillarsText = "";
            this.selectedPillars.forEach((element) => {
                this.selectedPillarsText += element.regionName + ", ";
            });
            this.selectedPillarsText = this.selectedPillarsText.substring(0, this.selectedPillarsText.length - 2);
        },
        onStepOptionCheck() {
            this.selectedStepsText = "";
            this.selectedSteps.forEach((element) => {
                this.selectedStepsText += element + ", ";
            });
            this.selectedStepsText = this.selectedStepsText.substring(0, this.selectedStepsText.length - 2);
        },
        markAllOptionCheck() {
            this.selectedPillarsText = "";
            this.selectedPillars = JSON.parse(JSON.stringify(this.multiSelectPillarList));
            this.selectedPillars.forEach((element) => {
                this.selectedPillarsText += element.regionName + ", ";
            });
            this.selectedPillarsText = this.selectedPillarsText.substring(0, this.selectedPillarsText.length - 2);
        },
        markAllStepOptionCheck() {
            this.selectedStepsText = "";
            this.selectedSteps = JSON.parse(JSON.stringify(this.multiSelectStepList));
            this.selectedSteps.forEach((element) => {
                this.selectedStepsText += element + ", ";
            });
            this.selectedStepsText = this.selectedStepsText.substring(0, this.selectedStepsText.length - 2);
        },
        onConfirmClick() {
            if (this.showOption) {
                this.apiCallByCurrentTab();
            }
            this.showOption = false;
        },
        classificationStatusM(obj) {
            let pos = this.machineList.findIndex((item) => item.machineId === this.machineId);
            pos = this.classificationList.findIndex((item) => item.statusId === this.machineList[pos].replicatingStatusId);

            if (pos >= 0) return this.classificationList[pos].statusName;
            else return "";
        },
        classificationStatusP(obj) {
            let pos = this.classificationList.findIndex((item) => item.statusId === obj.statusId);
            console.log(obj);
            if (pos >= 0) return this.classificationList[pos].statusName;
            else return "";
        },
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.MRCOMMITTEDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = this.committedYr ? this.committedYr : new Date().getFullYear();
                this.getPlantDetails();
                this.getClassification();
            });
        },
        getClassification() {
            ApiCalls.httpCallDetails(Api.GETSTATUSDROPDOWN, "get").then((data) => {
                console.log(data);
                this.classificationList = data.data;
                this.classificationList.unshift({
                    statusId: "all",
                    statusName: "All",
                    isActive: true,
                    mrMachineStatusDetails: []
                });
                this.classificationSelected = "all";
            });
        },
        onClassificationChange() {
            if (this.activeTab === "machine") {
                this.getMachineFilter();
            } else {
                this.apiCallByCurrentTab();
            }
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
            });
        },
        plantChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.getWorkCenterDetails();
        },
        getWorkCenterDetails() {
            ApiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;
                this.workcenterId = data.data[0].workcenterId;
                this.getMachineFilter();
                this.getPillarDetails();
                this.getRegionList();
            });
        },
        wcChanged() {
            this.showLoader = true;
            this.classificationSelected = "all";
            this.getMachineFilter();
        },
        getRegionList() {
            ApiCalls.httpCallDetails(Api.GETREGIONLIST, "get").then((data) => {
                data.data.shift();
                this.regionList = data.data;
                this.regionId = data.data[0].regionId;
            });
        },
        getMachineFilter() {
            let machinePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.workcenterId,
                regionId: this.plantId.regionId
            };

            ApiCalls.httpCallDetails(Api.MRMACHINEDROPDOWN, "post", machinePayload).then((data) => {
                this.machineList = data.data;

                this.machineList = this.machineList.map((m) => {
                    m.replicatingStatusId = m.replicatingStatusId ? m.replicatingStatusId : 1;
                    return m;
                });
                if (this.classificationSelected !== "all") this.machineList = this.machineList.filter((item) => item.replicatingStatusId === this.classificationSelected);
                if (data.data && data.data.length && this.machineList.length) {
                    // this.machineList.forEach(m => { m.replicatingStatusId = m.replicatingStatusId ?? 1 })
                    console.log(this.machineList);
                    this.machineId = this.machineList[0].machineId;
                    this.apiCallByCurrentTab();
                } else {
                    this.machineList = [
                        {
                            machineId: 0,
                            machineName: "No machine exist"
                        }
                    ];
                    this.machineId = this.machineList[0].machineId;
                    this.apiCallByCurrentTab();
                }
            });
        },
        getPillarDetails() {
            ApiCalls.httpCallDetails(Api.MRPILLARDETAILS, "get").then((data) => {
                this.pillarList = data.data;
                this.pillarPrevSelected = data.data[0].pillarId;
                this.multiSelectPillarList = data.data.map((pillar) => ({
                    pillarId: pillar.pillarId,
                    pillarName: pillar.pillarName,
                    pillarCode: pillar.pillarCode,
                    active: "Y",
                    checkedBox: true,
                    regionName: pillar.pillarCode
                }));
                this.markAllOptionCheck();

                this.multiSelectStepList = [];
                for (let index = 0; index < this.maxStepPillarWise[data.data[0].pillarCode]; index++) {
                    this.multiSelectStepList.push(index + 1);
                }
                this.markAllStepOptionCheck();
                this.apiCallByCurrentTab();
            });
        },
        onPillarChange() {
            this.multiSelectStepList = [];
            for (let index = 0; index < this.maxStepPillarWise[this.selectedPillarName]; index++) {
                this.multiSelectStepList.push(index + 1);
            }
            this.showLoader = true;
            this.markAllStepOptionCheck();
            this.apiCallByCurrentTab();
        },
        apiCallByCurrentTab() {
            switch (this.activeTab) {
                case "machine":
                    this.getDataForMachineReport();
                    break;
                case "pillar":
                    this.getDataForPillarReport();
                    break;
                case "monthly":
                    this.getDataForMonthlyReport();
                    break;
                case "yearly":
                    this.getDataForYearlyReport();
                    break;
                default:
                    break;
            }
        },
        getDataForMachineReport() {
            this.graphDatas = [];
            this.showLoader = true;
            let url = `${Api.GETASSESSMENTREPORT1}/${this.committedYr}/${this.machineId}`;
            ApiCalls.httpCallDetails(url, "get").then((data) => {
                this.graphDatas = data.data;

                setTimeout(() => {
                    this.machineGraph();
                }, 1000);
            });
        },
        getDataForPillarReport() {
            this.graphDatas = [];
            this.showLoader = true;
            let payload = {
                plantId: this.plantId.plantId,
                pillarId: this.pillarPrevSelected,
                workcenterId: this.workcenterId,
                committedYear: this.committedYr,
                statusId: this.classificationSelected === "all" ? 0 : this.classificationSelected
            };
            ApiCalls.httpCallDetails(Api.GETASSESSMENTREPORT2, "post", payload).then((data) => {
                data.data.sort((a, b) => {
                    if (a.machineName > b.machineName) return 1;
                    else return a.machineName === b.machineName ? 0 : -1;
                });
                this.graphDatas = data.data;

                setTimeout(() => {
                    this.pillarGraph();
                }, 1000);
            });
        },
        getDataForMonthlyReport() {
            this.graphDatas = [];
            this.showLoader = true;

            let payLoad = {
                plantId: this.plantId.plantId,
                committedYear: `${this.monthYear.year}`,
                month: `${this.monthYear.monthIndex}`,
                pillarId: this.selectedPillars.map((pillar) => pillar.pillarId),
                wcId: `${this.workcenterId}`,
                statusId: this.classificationSelected === "all" ? 0 : this.classificationSelected
            };
            ApiCalls.httpCallDetails(Api.GETASSESSMENTREPORT3, "post", payLoad).then((data) => {
                let obj = {};
                let rawData = data.data;
                rawData.forEach((pillarWise) => {
                    pillarWise.machines.forEach((machine) => {
                        obj[machine.pillarCode] = obj.hasOwnProperty(machine.pillarCode) ? obj[machine.pillarCode] : {};
                        let status = this.classificationSelected === "all" ? this.classificationStatusP(machine) : "";
                        obj[machine.pillarCode]["labels"] = obj[machine.pillarCode].hasOwnProperty("labels")
                            ? [...obj[machine.pillarCode]["labels"], [machine.machineName, status]]
                            : [[machine.machineName, status]];
                        obj[machine.pillarCode]["targets"] = obj[machine.pillarCode].hasOwnProperty("targets") ? [...obj[machine.pillarCode]["targets"], machine.target] : [machine.target];
                        obj[machine.pillarCode]["currents"] = obj[machine.pillarCode].hasOwnProperty("currents")
                            ? [...obj[machine.pillarCode]["currents"], machine.current]
                            : [machine.current];
                        obj[machine.pillarCode]["audits"] = obj[machine.pillarCode].hasOwnProperty("audits") ? [...obj[machine.pillarCode]["audits"], machine.audit] : [machine.audit];
                        obj[machine.pillarCode]["statusId"] = this.classificationSelected !== "all" ? "- " + this.classificationStatusP(machine) : "";
                    });
                });
                let tempArr = [];
                Object.keys(obj).forEach((key) => {
                    let temp = {
                        statusId: obj[key].statusId,
                        pillarCode: key,
                        lables: obj[key].labels,
                        targets: obj[key].targets,
                        actions: obj[key].currents,
                        audits: obj[key].audits
                    };

                    tempArr.push(temp);
                });

                this.graphDatas = tempArr;

                setTimeout(() => {
                    this.monthlyGraph();
                }, 1000);
            });
        },
        randomHexColorx() {
            // Generate a random 2 digit hex number, padded with a 0 if necessary
            const part = () =>
                Math.floor(this.helper.getRandom() * 256)
                    .toString(16)
                    .padStart(2, "0");
            const r = part();
            const g = part();
            const b = part();
            return `#${r}${g}${b}`;
        },
        randomHexColor(pos) {
            // assign a unique hex number
            let colorSet = [
                "#FF0000",
                "#FF8700",
                "#FFD300",
                "#DEFF0A",
                "#A1FF0A",
                "#0AFF99",
                "#05356D",
                "#580AFF",
                "#BE0AFF",
                "#669900",
                "#CCEE66",
                "#006699",
                "#3399CC",
                "#990066",
                "#CC3399",
                "#FF6600",
                "#FF9900",
                "#FFCC00",
                "#99CC33",
                "#147DF5"
            ];
            return colorSet[pos];
        },
        getDataForYearlyReport() {
            this.graphDatas = [];
            this.showLoader = true;

            let payLoad = {
                year: this.committedYr,
                regionId: this.regionId,
                pillarId: this.pillarPrevSelected,
                value: this.selectedSteps
            };

            ApiCalls.httpCallDetails(Api.GETASSESSMENTREPORT4, "post", payLoad).then((data) => {
                this.graphDatas = [];
                const colorSet = {};
                const replicatingMachines = data.data.replicatingMachines;
                const totalMachines = data.data.totalMachines;
                Object.keys(replicatingMachines[0].plantData).forEach((plant, colorIndex) => {
                    colorSet[plant] = this.randomHexColor(colorIndex);
                });
                for (let index = 0; index < totalMachines.length; index++) {
                    let item = totalMachines[index];

                    let temp1 = {
                        forGraph: "total",
                        step: item.value,
                        pillarCode: item.pillarCode,
                        labels: ["Plant", ...item.listOfMonths],
                        plantList: {},
                        tableRows: []
                    };
                    let totalObj = {
                        Plant: "",
                        JAN: 0,
                        FEB: 0,
                        MAR: 0,
                        APR: 0,
                        MAY: 0,
                        JUN: 0,
                        JUL: 0,
                        AUG: 0,
                        SEP: 0,
                        OCT: 0,
                        NOV: 0,
                        DEC: 0
                    };
                    //color set array for futher purpose["#A1CD49", "#D2EBFF", "#8B8BC3", "#94C8DB", "#109B10", "#33CFD3", "#FECE79", "#0E5E9D", "#97D1A9", "#F48170", "#518BC9"];

                    Object.keys(item.plantData).forEach((plant, _colorIndex) => {
                        const obj = {};
                        temp1.labels.forEach((label, month) => {
                            if (month === 0) {
                                obj[label] = { plantName: plant, plantColor: colorSet[plant] };
                                totalObj[label] += "";
                            } else {
                                obj[label] = item.plantData[plant][month - 1];
                                totalObj[label] += item.plantData[plant][month - 1];
                            }
                        });
                        totalObj["Plant"] = { plantName: "Total", plantColor: "transparent" };
                        temp1.tableRows.push(obj);
                        temp1.plantList[plant] = item.plantData[plant].map((val, pos) => (val ? val : null));
                    });
                    temp1.tableRows.push(totalObj);
                    this.graphDatas.push(temp1);

                    item = replicatingMachines[index];

                    let temp2 = {
                        forGraph: "total",
                        step: item.value,
                        pillarCode: item.pillarCode,
                        labels: ["Plant", ...item.listOfMonths],
                        plantList: {},
                        tableRows: []
                    };
                    totalObj = {
                        Plant: "",
                        JAN: 0,
                        FEB: 0,
                        MAR: 0,
                        APR: 0,
                        MAY: 0,
                        JUN: 0,
                        JUL: 0,
                        AUG: 0,
                        SEP: 0,
                        OCT: 0,
                        NOV: 0,
                        DEC: 0
                    };
                    Object.keys(item.plantData).forEach((plant, _colorIndex) => {
                        let obj = {};

                        temp2.labels.forEach((label, month) => {
                            if (month === 0) {
                                obj[label] = { plantName: plant, plantColor: colorSet[plant] };
                                totalObj[label] += "";
                            } else {
                                obj[label] = item.plantData[plant][month - 1];
                                totalObj[label] += item.plantData[plant][month - 1];
                            }
                        });
                        totalObj["Plant"] = { plantName: "Total", plantColor: "transparent" };
                        temp2.tableRows.push(obj);
                        temp2.plantList[plant] = item.plantData[plant].map((val, _pos) => (val ? val : null));
                    });
                    temp2.tableRows.push(totalObj);
                    this.graphDatas.push(temp2);
                }

                setTimeout(() => {
                    this.printData = [];
                    this.yearlyGraphTotal();
                    this.yearlyGraphReplication();
                }, 1000);
            });
        },
        machineGraph() {
            this.printData = [];
            this.graphDatas.forEach((data, pos) => {
                this.printData.push("assessment-graph-div-" + pos);
            });
            this.graphDatas.forEach((type, pos) => {
                const machineData = JSON.parse(JSON.stringify(this.forAuditTargetCurrentTemplate));
                const v0 = type.audits.map((value) => (value ? value : 0));
                const v1 = type.targets.map((value) => (value ? value : 0));
                const v2 = type.actions.map((value) => (value ? value : 0));
                if (type.pillarCode === "WPO") {
                    machineData.options.scales.y1.suggestedMax = 5;
                    machineData.options.scales.y1.title.text = "Audit Score";
                }
                machineData.data.labels = type.lables;
                machineData.data.datasets[0].data = v0;
                machineData.data.datasets[1].data = v1;
                machineData.data.datasets[2].data = v2;
                machineData.data.datasets[2].backgroundColor = v2.map((value, i) => (value < v1[i] ? "#f75357" : "#109b10"));
                machineData.data.datasets[2].datalabels.labels.value.color = v2.map((value, i) => (value < v1[i] ? "#f75357" : "#109b10"));
                Chart.register(ChartJsPluginDataLabels);
                const ctx = document.getElementById("assessment-graph-" + pos);
                const obj = new Chart(ctx, machineData);
                obj.draw();
            });
            this.showLoader = false;
        },
        pillarGraph() {
            this.printData = [];
            this.graphDatas.forEach((_data, pos) => {
                this.printData.push("assessment-graph-div-" + pos);
            });
            this.graphDatas.forEach((type, pos) => {
                const pillarData = JSON.parse(JSON.stringify(this.forAuditTargetCurrentTemplate));
                const v0 = type.scores.map((value) => (value ? value : 0));
                const v1 = type.targets.map((value) => (value ? value : 0));
                const v2 = type.currents.map((value) => (value ? value : 0));
                if (this.selectedPillarName === "WPO") {
                    pillarData.options.scales.y1.suggestedMax = 5;
                    pillarData.options.scales.y1.title.text = "Audit Score";
                }
                pillarData.data.labels = type.months;
                pillarData.data.datasets[0].data = v0;
                pillarData.data.datasets[1].data = v1;
                pillarData.data.datasets[2].data = v2;
                pillarData.data.datasets[2].backgroundColor = v2.map((value, i) => (value < v1[i] ? "#f75357" : "#109b10"));
                pillarData.data.datasets[2].datalabels.labels.value.color = v2.map((value, i) => (value < v1[i] ? "#f75357" : "#109b10"));

                Chart.register(ChartJsPluginDataLabels);
                let ctx = document.getElementById("assessment-graph-" + pos);
                let obj = new Chart(ctx, pillarData);
                obj.draw();
            });
            this.showLoader = false;
        },
        monthlyGraph() {
            this.printData = [];
            this.graphDatas.forEach((data, pos) => {
                this.printData.push("monthly-preview-graph-div-" + pos);
            });
            this.graphDatas.forEach((type, pos) => {
                let machineData = JSON.parse(JSON.stringify(this.forMonthlyPreviewTemplate));
                let v0 = type.audits.map((value) => (value ? value : 0));
                let v1 = type.targets.map((value) => (value ? value : 0));
                let v2 = type.actions.map((value) => (value ? value : 0));

                if (type.pillarCode === "WPO") {
                    machineData.options.scales.x1.suggestedMax = 5;
                    machineData.options.scales.x1.ticks = { stepSize: 1 };
                    machineData.options.scales.x1.title.text = "Audit Score";
                }
                switch (type.pillarCode) {
                    case "DEC":
                        machineData.options.scales.x.suggestedMax = 8;
                        machineData.options.scales.x.ticks = { stepSize: 1 };
                        break;
                    case "WPO":
                        machineData.options.scales.x.suggestedMax = 6;
                        machineData.options.scales.x.ticks = { stepSize: 1 };
                        break;
                    case "CSD":
                    case "RE":
                        machineData.options.scales.x.suggestedMax = 7;
                        machineData.options.scales.x.ticks = { stepSize: 1 };
                        break;

                    default:
                        break;
                }

                machineData.data.labels = type.lables;
                machineData.data.datasets[0].radius = 10;
                machineData.data.datasets[0].borderColor = "#00000000";
                machineData.data.datasets[0].data = v0;
                machineData.data.datasets[1].data = v1;
                machineData.data.datasets[2].data = v2;
                machineData.data.datasets[2].backgroundColor = v2.map((value, i) => (value < v1[i] ? "#f75357" : "#109b10"));

                Chart.register(ChartJsPluginDataLabels);
                let ctx = document.getElementById("monthly-preview-graph-" + pos);
                let obj = new Chart(ctx, machineData);
                obj.draw();
            });
            this.showLoader = false;
        },
        yearlyGraphTotal() {
            this.graphDatas.forEach((data, pos) => {
                this.printData.push("yearly-graph-div-" + pos);
            });

            this.graphDatas.forEach((type, pos) => {
                let machineData = JSON.parse(JSON.stringify(this.forYearlyStackedTemplate));
                machineData.data.labels = JSON.parse(JSON.stringify(type.labels));
                machineData.data.labels.shift();
                Object.keys(type.plantList).forEach((key, colorIndex) => {
                    let datasetTemplate = JSON.parse(JSON.stringify(machineData.data.datasets[0]));
                    datasetTemplate.label = key;
                    datasetTemplate.data = type.plantList[key];
                    datasetTemplate.backgroundColor = type.tableRows[colorIndex]["Plant"].plantColor;
                    machineData.data.datasets.push(datasetTemplate);
                });
                machineData.data.datasets.shift();
                Chart.register(ChartJsPluginDataLabels);

                if (type.forGraph === "total") {
                    let ctx = document.getElementById("yearly-total-graph-" + pos);
                    let obj = new Chart(ctx, machineData);
                    obj.draw();
                }
            });
            this.showLoader = false;
        },
        yearlyGraphReplication() {
            this.graphDatas.forEach((data, pos) => {
                this.printData.push("yearly-replication-div-" + pos);
            });

            this.graphDatas.forEach((type, pos) => {
                let machineData = JSON.parse(JSON.stringify(this.forYearlyStackedTemplate));
                machineData.data.labels = JSON.parse(JSON.stringify(type.labels));
                machineData.data.labels.shift();
                Object.keys(type.plantList).forEach((key, colorIndex) => {
                    let datasetTemplate = JSON.parse(JSON.stringify(machineData.data.datasets[0]));
                    datasetTemplate.label = key;
                    datasetTemplate.data = type.plantList[key];
                    datasetTemplate.backgroundColor = type.tableRows[colorIndex]["Plant"].plantColor;
                    machineData.data.datasets.push(datasetTemplate);
                });
                machineData.data.datasets.shift();
                Chart.register(ChartJsPluginDataLabels);

                if (type.forGraph === "replication") {
                    let ctx = document.getElementById("yearly-replication-graph-" + pos);
                    let obj = new Chart(ctx, machineData);
                    obj.draw();
                }
            });
            this.showLoader = false;
        }
    }
};
</script>

<style scoped>
/* tabs */

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: inline-flex;
}

.outer-block {
    background-color: #fff;
    border-radius: 6px;
}

.hr {
    position: relative;
    top: -26px;
}

/* tabs */
.lable-text {
    z-index: 2;
}

.multi-select {
    position: relative;
}

.multi-select ul {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    box-shadow: 2px 1px 4px 0.1rem rgb(0 0 0 / 25%);
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    position: absolute;
    top: 0;
    width: 20rem;
    list-style: none;
    max-height: 150px;
    z-index: 2;
    background-color: #fff;
    overflow: auto;
}

.multi-select ul li {
    padding: 1rem;
}

.graph-div {
    width: 100%;
    display: flex;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem;
}

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;
    margin-left: 1rem;
    font-size: 1.33rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.download-view:hover {
    background: #e3e8f7;
}

.action-btn {
    border: 1px #274ba7 solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    cursor: pointer;
}

.plant-dd {
    width: 20.5%;
}

.one-graph-per-row {
    width: 100% !important;
}

.two-graph-per-row {
    width: 49% !important;
}

.graph-1-container {
    width: 100%;
    padding: 2rem;
    color: #505d6f;
    font: 1rem BarlowR, sans-serif;
    display: flex;
    justify-content: space-between;
}

.graph-2-container {
    width: 100%;
    padding: 1rem 2rem;
    color: #505d6f;
    font: 1rem BarlowR, sans-serif;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.value-font {
    font: 1.16rem "BarlowSB", sans-serif;
}

.legends {
    margin: 0 0.5rem;
    border: 1px solid #d3d3d3;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
}

.ST-value {
    background-color: #ee90d2;
}

.AT-value {
    background-color: #f75357;
}

.OT-value {
    background-color: #94c8db;
}

.EF-value {
    background-color: #f4f88c;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
